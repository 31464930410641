






















import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { isEqual, merge } from 'lodash';
import { DeviceRelation } from '@/models/data/models';
import {
  ModelConfig,
  StreamConfig,
  TileConfig,
  DeviceBoxData,
  DeviceBoxState,
  BatteryStatus,
} from '@/apps/monitoring/interfaces';
import {
  getRandomTimeoutMsec,
  mergeDeviceBoxData,
} from '@/apps/monitoring/helpers';
import {
  DeviceDataEvent,
  DeviceEventLogEventQuery,
  DeviceEventType,
} from '@/models/device/interfaces';
import { Device, DeviceEventLog } from '@/models/device/models';
import { globalStore } from '@/store/modules/global';
import { getState, parseData } from '@/apps/monitoring/parser';
import { deepCopy } from '@/util/util';
import DeviceBox from './DeviceBox.vue';

@Component({
  components: {
    DeviceBox,
  },
})
export default class DeviceBoxContainer extends Vue {
  @Prop({ required: true }) deviceRelationId!: string;
  @Prop({ default: 15 }) pollInterval!: number;
  @Prop({ required: true }) canEditDeviceSettings!: boolean;
  @Prop({ required: true }) showAsBox!: boolean;
  @Prop({ required: true }) tileEnabled!: boolean;
  @Prop({ required: false }) parentTileConfig!: TileConfig;
  @Prop({ default: true }) hasDetailView!: boolean;

  loaded = false;
  deviceRelation: DeviceRelation | null = null;
  device: Device | null = null;
  deviceBoxData: DeviceBoxData = { parameters: [], time: 'N/A' };
  eventIntervalId = 0;
  configIntervalId = 0;
  gatewayNameMap = new Map<string, string>();
  customModelConfig: ModelConfig | null = null;
  hasCustomModelConfig = false;
  loadedTileConfig: TileConfig = {
    pid: '',
    enabled: true,
    lastDisableEvent: '',
  };
  batteryStatus: BatteryStatus | null = null;
  hideValueWhenChargingOrOnCharger = false;
  hasBatteryInfo = true;

  @Watch('pollInterval')
  async onPollIntervalChanged(): Promise<void> {
    await this.init();
  }

  get tileConfig(): TileConfig {
    if (this.showAsBox) {
      return this.loadedTileConfig;
    } else {
      return this.parentTileConfig;
    }
  }

  get deviceBoxState(): DeviceBoxState {
    const deviceBoxState = getState(
      this.deviceBoxData,
      this.tileEnabled && this.tileConfig.enabled,
      this.tileConfig.lastDisableEvent,
      this.hideValue,
    );
    this.$emit('stateUpdate', this.deviceRelationId, deviceBoxState);
    return deviceBoxState;
  }

  get hideValue(): boolean {
    if (this.hideValueWhenChargingOrOnCharger) {
      return (
        this.batteryStatus?.is_charging ||
        this.batteryStatus?.is_on_charger ||
        false
      );
    } else {
      return false;
    }
  }

  async mounted(): Promise<void> {
    await this.init();
  }

  destroyed(): void {
    this.stopInterval();
  }

  async init(): Promise<void> {
    this.stopInterval();
    const timeout = getRandomTimeoutMsec(this.pollInterval, 3, 10);
    try {
      this.deviceRelation = await this.$apiv2.get(
        DeviceRelation,
        this.deviceRelationId,
      );
      if (!this.deviceRelation) {
        throw new Error('Device relation not found');
      }
      this.device = await this.$apiv2.get<Device>(
        Device,
        this.deviceRelation.device,
      );
      await this.loadTileConfig();
      await this.getEvent();
      this.eventIntervalId = setInterval(() => {
        this.getEvent();
      }, timeout);
      this.configIntervalId = setInterval(() => {
        this.loadTileConfig();
      }, timeout * 2);
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
  }

  stopInterval(): void {
    if (this.eventIntervalId !== undefined) {
      clearInterval(this.eventIntervalId);
    }
    if (this.configIntervalId !== undefined) {
      clearInterval(this.configIntervalId);
    }
  }

  async getEvent(): Promise<void> {
    try {
      const setting = globalStore.clientAppSetting('device_models');
      if (setting?.value) {
        const deviceModels: ModelConfig[] = setting.value.device_models || [];
        let modelConfig: ModelConfig | null =
          deepCopy(deviceModels.find(m => m.id === this.device?.model)) ?? null;
        this.hasCustomModelConfig = this.customModelConfig
          ? !isEqual(this.customModelConfig, modelConfig)
          : false;
        if (this.customModelConfig) {
          modelConfig = merge(modelConfig, this.customModelConfig);
        }

        this.hideValueWhenChargingOrOnCharger =
          modelConfig?.monitoringConfig?.hideValueWhenChargingOrOnCharger ??
          false;

        this.hasBatteryInfo =
          modelConfig?.monitoringConfig?.hasBatteryInfo ?? true;

        if (modelConfig?.monitoringConfig?.streams?.length) {
          const deviceBoxDataArray: DeviceBoxData[] = [];
          for (const streamConfig of modelConfig.monitoringConfig.streams) {
            const query: DeviceEventLogEventQuery = {
              device: this.deviceRelation?.device,
              page: 1,
              page_size: 1,
              identity: streamConfig.stream,
              event_type: DeviceEventType.DEVICE_DATA,
            };
            const response = await DeviceEventLog.queryEvents<DeviceDataEvent>(
              query,
            );
            if (response?.results?.length) {
              const event = response.results[0];
              const deviceBoxData = await this.parseEvent(event, streamConfig);
              deviceBoxDataArray.push(deviceBoxData);
            }
          }
          this.deviceBoxData = mergeDeviceBoxData(deviceBoxDataArray);
        }
        this.loaded = true;
      }
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
  }

  /**
   * Get monitoring config for tile from device setting
   */
  async loadTileConfig(): Promise<void> {
    try {
      if (!this.deviceRelation) {
        throw new Error('Device relation not found');
      }
      const settings = await Device.getSettings(
        this.$apiv2,
        this.deviceRelation.device,
      );
      const tileConfigSetting = settings.find(
        s => s.key === 'monitoring_config',
      );
      if (tileConfigSetting?.value) {
        this.loadedTileConfig = {
          ...this.loadedTileConfig,
          ...tileConfigSetting.value,
        };
      }
      const customModelConfig = settings.find(
        s => s.key === 'custom_model_config',
      );
      if (customModelConfig?.value?.custom_model_config) {
        this.customModelConfig = customModelConfig.value.custom_model_config;
      } else {
        this.customModelConfig = null;
      }
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
  }

  async parseEvent(
    event: DeviceDataEvent,
    streamConfig: StreamConfig,
  ): Promise<DeviceBoxData> {
    const deviceBoxData = parseData(event, streamConfig);
    if (streamConfig.gatewayName?.show && event.payload.gateway_info) {
      // get name of gateway that read this event
      let gatewayName = this.gatewayNameMap.get(
        event.payload.gateway_info.device_id,
      );
      if (!gatewayName) {
        try {
          gatewayName = await Device.getName(
            event.payload.gateway_info.device_id,
            event.payload.gateway_info.model,
          );
          this.gatewayNameMap.set(
            event.payload.gateway_info.device_id,
            gatewayName,
          );
        } catch (error) {
          this.$errorHandler.handleError(error);
        }
      }
      if (gatewayName) {
        deviceBoxData.additionalFields = deviceBoxData.additionalFields || [];
        deviceBoxData.additionalFields.push({
          ...streamConfig.gatewayName,
          id: 'gateway_name',
          label: streamConfig.gatewayName.label || 'Gateway',
          value: gatewayName,
        });
      }
    }
    if (streamConfig.additionalFields?.length) {
      // get additional field configured for this stream
      deviceBoxData.additionalFields = deviceBoxData.additionalFields || [];
      deviceBoxData.additionalFields = [
        ...deviceBoxData.additionalFields,
        ...streamConfig.additionalFields,
      ];
    }
    return deviceBoxData;
  }

  async writeTileConfig(tileConfig: TileConfig): Promise<void> {
    const loading = this.$buefy.loading.open({});
    try {
      if (!this.deviceRelation) {
        throw new Error('Device relation not found');
      }
      await Device.setSetting(
        this.$apiv2,
        this.deviceRelation.device,
        'monitoring_config',
        tileConfig,
      );
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loading.close();
  }

  async removeCustomModelConfig(): Promise<void> {
    const loading = this.$buefy.loading.open({});
    try {
      if (!this.deviceRelation) {
        throw new Error('Device relation not found');
      }
      this.customModelConfig = null;
      this.hasCustomModelConfig = false;
      // also works if setting does not exist
      await Device.deleteSetting(
        this.$apiv2,
        this.deviceRelation.device,
        'custom_model_config',
      );
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loading.close();
  }

  onBatteryStatusChanged(batteryStatus: BatteryStatus): void {
    this.batteryStatus = batteryStatus;
  }
}

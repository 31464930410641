export function sortObjectsByArrayOfPropertyValues<T>(
  objects: T[],
  property: keyof T,
  order: T[typeof property][],
): void {
  objects.sort((object1, object2) => {
    let index1 = order.findIndex(p => p === object1[property]);
    let index2 = order.findIndex(p => p === object2[property]);
    // put objects with values that are not in order array at the end
    if (index1 === -1) {
      index1 = Infinity;
    }
    if (index2 === -1) {
      index2 = Infinity;
    }
    return index1 - index2;
  });
}








































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { DeviceRelation } from '@/models/data/models';
import { Device, DeviceSetting } from '@/models/device/models';
import {
  TileConfig,
  TileState,
  ModelConfig,
  MonitoringDashboardMode,
  DeviceBoxState,
} from '@/apps/monitoring/interfaces';
import { Location } from 'vue-router';
import { monitoringRouteName } from '@/apps/monitoring/app';
import {
  getRandomTimeoutMsec,
  getColorFromValue,
  TileColorMap,
} from '@/apps/monitoring/helpers';
import { globalStore } from '@/store/modules/global';
import {
  filterAndSortDeviceRelations,
  getDevicesAssignedToGateway,
} from '@/apps/monitoring/modeGateway';
import MultilineTooltip from '@/components/common/MultilineTooltip.vue';
import DeviceBoxContainer from './DeviceBoxContainer.vue';
import DeviceHeader from './DeviceHeader.vue';
import BatteryData from './BatteryData.vue';
import { sleep } from '@/util/async';

@Component({
  components: {
    MultilineTooltip,
    DeviceBoxContainer,
    DeviceHeader,
    BatteryData,
  },
})
export default class DeviceTile extends Vue {
  @Prop({ required: true }) deviceRelationId!: string;
  @Prop({ default: 15 }) pollInterval!: number;
  @Prop({ required: true }) canEditDeviceSettings!: boolean;
  @Prop({ required: true }) mode!: MonitoringDashboardMode;
  @Prop({ default: true }) hasDetailView!: boolean;
  @Prop({ default: 0 }) delayInit!: number;

  $refs!: {
    pidInput: any;
  };

  loaded = false;
  deviceRelation: DeviceRelation | null = null;
  device: Device | null = null;
  tileConfig: TileConfig = {
    pid: '',
    enabled: true,
    lastDisableEvent: '',
  };
  deviceNameLabel = '';
  hasBatteryInfo = false;
  configIntervalId = 0;
  tileState: TileState = {
    color: 'is-white',
    isCurrentPid: false,
    enabled: false,
  };
  isEditingPid = false;
  pidInputValue = '';
  deviceRelations: DeviceRelation[] = [];
  deviceBoxStates = new Map<string, DeviceBoxState>();

  @Watch('pollInterval')
  async onPollIntervalChanged(): Promise<void> {
    await this.init();
  }

  get detailLocation(): Location {
    if (!this.deviceRelation) {
      return { name: monitoringRouteName('dashboard-list') };
    }
    return {
      name: monitoringRouteName('dashboard-detail'),
      params: {
        id: this.deviceRelation?.id,
      },
    };
  }

  get showBatteryInfo(): boolean {
    return this.mode === 'gateway' && this.hasBatteryInfo === true;
  }

  async mounted(): Promise<void> {
    await sleep(this.delayInit);
    await this.init();
  }

  destroyed(): void {
    this.stopInterval();
  }

  async init(): Promise<void> {
    this.stopInterval();
    const timeout = getRandomTimeoutMsec(this.pollInterval, 3, 10);
    try {
      this.deviceRelation = await this.$apiv2.get<DeviceRelation>(
        DeviceRelation,
        this.deviceRelationId,
      );
      this.device = await this.$apiv2.get<Device>(
        Device,
        this.deviceRelation.device,
      );
      if (this.mode === 'gateway') {
        const deviceRelations = await getDevicesAssignedToGateway(this.device);
        this.deviceRelations = filterAndSortDeviceRelations(deviceRelations);
      }
      await this.loadTileConfig();
      this.configIntervalId = setInterval(() => {
        this.loadTileConfig();
      }, timeout * 2);
      this.loaded = true;
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
  }

  stopInterval(): void {
    if (this.configIntervalId !== undefined) {
      clearInterval(this.configIntervalId);
    }
  }

  /**
   * Get monitoring config for tile from device setting
   */
  async loadTileConfig(): Promise<void> {
    try {
      if (!this.deviceRelation) {
        throw new Error('Device relation not found');
      }
      let tileConfigSetting: DeviceSetting | undefined = undefined;
      try {
        tileConfigSetting = await Device.getSetting(
          this.$apiv2,
          this.deviceRelation.device,
          'monitoring_config',
        );
      } catch (error) {
        if (error.response && error.response.status === 404) {
          // device setting does note exist -> continue
        } else {
          throw error;
        }
      }
      if (tileConfigSetting?.value) {
        this.tileConfig = {
          ...this.tileConfig,
          ...tileConfigSetting.value,
        };
      }
      const setting = globalStore.clientAppSetting('device_models');
      if (setting?.value) {
        const deviceModels: ModelConfig[] = setting.value.device_models || [];
        const modelConfig = deviceModels.find(m => m.id === this.device?.model);
        this.deviceNameLabel =
          modelConfig?.monitoringConfig?.deviceNameLabel ?? '';
        this.hasBatteryInfo =
          modelConfig?.monitoringConfig?.hasBatteryInfo ?? false;
      }
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
  }

  enable(): void {
    this.tileConfig.enabled = true;
    this.writeTileConfig();
    this.updateTileState();
  }

  disable(): void {
    this.tileConfig.lastDisableEvent = new Date().toISOString();
    this.tileConfig.enabled = false;
    this.tileConfig.pid = '';
    this.pidInputValue = '';
    this.writeTileConfig();
    this.isEditingPid = false;
    this.updateTileState();
  }

  async clickEditPid(): Promise<void> {
    if (this.isEditingPid) {
      this.tileConfig.pid = this.pidInputValue;
      this.writeTileConfig();
    }
    this.isEditingPid = !this.isEditingPid;
    if (this.isEditingPid) {
      this.pidInputValue = this.tileConfig.pid;
      this.setPidFocus();
    }
  }

  setPidFocus(): void {
    this.$nextTick(() => {
      this.$refs.pidInput?.focus();
    });
  }

  async writeTileConfig(): Promise<void> {
    const loading = this.$buefy.loading.open({});
    try {
      if (!this.deviceRelation) {
        throw new Error('Device relation not found');
      }
      await Device.setSetting(
        this.$apiv2,
        this.deviceRelation.device,
        'monitoring_config',
        this.tileConfig,
      );
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loading.close();
  }

  onStateUpdate(id: string, deviceBoxState: DeviceBoxState): void {
    this.deviceBoxStates.set(id, deviceBoxState);
    this.updateTileState();
  }

  updateTileState(): void {
    if (!this.tileConfig.enabled) {
      this.tileState.color = 'is-white';
      return;
    } else if (this.deviceBoxStates.size === 0) {
      this.tileState.color = 'is-warning';
      return;
    }
    let colorValue = TileColorMap['is-white'];
    // color of the tile is the 'highest' color of all device boxes contained in that tile
    this.deviceBoxStates.forEach(state => {
      if (TileColorMap[state.color] > colorValue) {
        colorValue = TileColorMap[state.color];
      }
    });
    this.tileState.color = getColorFromValue(colorValue);
  }
}

import {
  ParameterState,
  DeviceBoxState,
  ParameterCompositeState,
  TileParameter,
  DeviceBoxData,
  DisplayTransformation,
  TileColor,
  Thresholds,
} from './interfaces';
import { dateNow } from '@/util/time';

/**
 * Return a random value between mean - range and mean + range times 1000
 * Optionally specifiy a minimal value
 * @param mean sec
 * @param range sec
 * @param minSec sec
 */
export function getRandomTimeoutMsec(
  mean: number,
  range: number,
  minSec?: number,
): number {
  const value = Math.round((mean - range + Math.random() * 2 * range) * 1000);
  if (minSec === undefined) {
    return value;
  } else {
    return Math.max(value, minSec * 1000);
  }
}

/**
 * Check one value against thresholds, returns:
 * - true if value is within thresholds, or no thresholds are given
 * - false if value is outside of threshold
 * - null if value is undefined or null
 * @param value
 * @param thresholds
 */
export function checkValueWithinThreshold(
  value?: number | null,
  thresholds?: Thresholds,
): boolean | null {
  if (!thresholds) {
    // no thresholds available
    return true;
  } else {
    if (value === undefined || value === null) {
      // no data available
      return null;
    } else {
      return value >= thresholds.lower && value <= thresholds.upper;
    }
  }
}

/**
 * Check a timestamp against thresholds
 * @param time as timestamp
 * @param thresholds in seconds relative to now
 */
export function checkTimeThreshold(
  time?: string,
  thresholds?: Thresholds,
): boolean | null {
  if (time === undefined) {
    return null;
  }
  const age = getAge(time);
  return checkValueWithinThreshold(age, thresholds);
}

/**
 * Get age of a datetime string in seconds
 * @param time datetime as string, has to be parseable by new Date()
 * @return age in seconds
 */
export function getAge(time: string): number | null {
  const date = new Date(time);
  if (isNaN(date.valueOf())) {
    return null;
  }
  const now = dateNow();
  // event age in seconds
  const age = (now - date.getTime()) / 1000;
  return age;
}

/**
 * helper function to get state of parameter from substates of value, quality and time
 * @param p
 */
export function getParamState(
  parameter?: ParameterCompositeState,
): ParameterState {
  if (parameter === undefined) {
    return ParameterState.OK;
  } else {
    const allStates = Object.values(parameter.state).map(
      n => n ?? ParameterState.OK,
    );
    return Math.max(...allStates);
  }
}

/**
 * Check if event is younger than last disable event
 * @param time
 * @param lastDisableEvent
 */
export function checkForNewData(
  time?: string,
  lastDisableEvent?: string | null,
): boolean {
  // no last disable timestamp
  if (!lastDisableEvent) {
    return true;
  }
  if (!time) {
    return false;
  }
  const date = new Date(time);
  // invalid time
  if (isNaN(date.valueOf())) {
    return false;
  }
  const lastDisableEventDate = new Date(lastDisableEvent);
  return date.getTime() - lastDisableEventDate.getTime() > 0;
}

export function transformValue(
  value?: number | string,
  transformation?: DisplayTransformation,
): string {
  if (value === undefined) {
    return '';
  }
  if (typeof value === 'string') {
    return value;
  }
  const scale = transformation?.scale ?? 1;
  const offset = transformation?.offset ?? 0;
  const fractionDigits = transformation?.fractionDigits ?? 0;
  return (scale * value + offset).toFixed(fractionDigits);
}

/**
 * Get list of parameters with all necessary information for displaying them
 * @param data
 * @param state
 */
export function getParameters(
  data: DeviceBoxData,
  state: DeviceBoxState,
): TileParameter[] {
  const params: TileParameter[] = [];
  /**
   * do not show values if
   * - tile is disabled (is-white) OR
   * - data is from last PID
   */

  data.parameters.forEach(param => {
    const paramState = state.parameters.find(p => p.id === param.id);
    if (paramState === undefined) {
      return;
    }
    const showNA =
      state.color === 'is-white' ||
      !state.hasNewData ||
      getParamState(paramState) === ParameterState.HIDDEN;
    params.push({
      ...param,
      state: {
        ...paramState?.state,
        all: getParamState(paramState),
      },
      display: {
        value: showNA
          ? param.hiddenValue ?? 'N/A'
          : transformValue(param.value, param.displayTransformation),
        unit: showNA ? '' : param.displayTransformation?.unit || param.unit,
      },
    });
  });
  return params;
}

export const TileColorMap: { [key in TileColor]: number } = {
  'is-white': 0,
  'is-success': 10,
  'is-warning': 20,
  'is-danger': 30,
};

export function getColorFromValue(value: number): TileColor {
  const color = Object.keys(TileColorMap).find(key => {
    return TileColorMap[key as TileColor] === value;
  }) as TileColor;
  return color;
}

export function mergeDeviceBoxData(
  deviceBoxDataArray: DeviceBoxData[],
): DeviceBoxData {
  const deviceBoxData: DeviceBoxData = {
    additionalFields: [],
    parameters: [],
    time: 'N/A',
  };
  deviceBoxDataArray.forEach(data => {
    deviceBoxData.additionalFields = [
      ...(deviceBoxData.additionalFields ?? []),
      ...(data.additionalFields ?? []),
    ];
    deviceBoxData.parameters = [
      ...deviceBoxData.parameters,
      ...data.parameters,
    ];
    if (deviceBoxData.time === 'N/A') {
      deviceBoxData.time = data.time;
    } else if (
      deviceBoxData.time !== undefined &&
      data.time !== undefined &&
      new Date(deviceBoxData.time).getTime() - new Date(data.time).getTime() > 0
    ) {
      // select oldest time
      deviceBoxData.time = data.time;
    }
  });
  return deviceBoxData;
}

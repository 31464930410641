



































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class DeviceHeader extends Vue {
  @Prop({ default: '' }) deviceNameLabel!: string;
  @Prop({ required: true }) deviceName!: string;
  @Prop({ required: true }) canEditDeviceSettings!: boolean;
  @Prop({ required: true }) enabled!: boolean;
  @Prop({ default: 'is-default' }) size!: 'is-default' | 'is-small';

  switchState = true;

  @Watch('enabled')
  onEnabledChanged(): void {
    this.switchState = this.enabled;
  }

  mounted(): void {
    this.onEnabledChanged();
  }

  get tooltipLabel(): string {
    return this.enabled
      ? this.$tc('common.disable')
      : this.$tc('common.enable');
  }

  onSwitchInput(value: boolean): void {
    // when disabling
    if (!value) {
      this.$buefy.dialog.confirm({
        message: this.$tc('monitoring.tile.confirmDisableDevice'),
        onConfirm: () => {
          this.$emit('disable');
        },
        onCancel: () => {
          this.switchState = true;
        },
      });
    } else {
      this.$emit('enable');
    }
  }
}

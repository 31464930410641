


















































import { Vue, Component, Prop } from 'vue-property-decorator';
import {
  TileState,
  DeviceBoxState,
  DashboardConfig,
} from '@/apps/monitoring/interfaces';
import { Location } from 'vue-router';
import { getColorFromValue, TileColorMap } from '@/apps/monitoring/helpers';
import MultilineTooltip from '@/components/common/MultilineTooltip.vue';
import DeviceBoxContainer from './DeviceBoxContainer.vue';
import DeviceHeader from './DeviceHeader.vue';
import {
  filterAndSortDeviceAssignments,
  getDevicesAssignedToParticipant,
} from '@/apps/monitoring/modeParticipant';
import { Participant } from '@/models/study/models';
import { studyRouteName } from '@/apps/study/app';
import { globalStore } from '@/store/modules/global';
import { sleep } from '@/util/async';

@Component({
  components: { MultilineTooltip, DeviceBoxContainer, DeviceHeader },
})
export default class ParticipantTile extends Vue {
  @Prop({ required: true }) participant!: Participant;
  @Prop({ default: 15 }) pollInterval!: number;
  @Prop({ required: true }) canEditDeviceSettings!: boolean;
  @Prop({ default: true }) hasDetailView!: boolean;
  @Prop({ default: 0 }) delayInit!: number;

  loaded = false;
  configIntervalId = 0;
  tileState: TileState = {
    color: 'is-white',
    isCurrentPid: false,
    enabled: false,
  };
  deviceRelationIds: string[] = [];
  deviceBoxStates = new Map<string, DeviceBoxState>();
  studyAppHandle = '';

  get detailLocation(): Location {
    return {
      name: studyRouteName('clinic.patient-detail'),
      params: {
        id: this.participant.id,
        app_handle: this.studyAppHandle,
      },
    };
  }

  async mounted(): Promise<void> {
    await sleep(this.delayInit);
    await this.init();
  }

  async init(): Promise<void> {
    try {
      const deviceAssignments = await getDevicesAssignedToParticipant(
        this.participant.id,
      );
      const filteredDeviceAssignments =
        filterAndSortDeviceAssignments(deviceAssignments);
      this.deviceRelationIds = filteredDeviceAssignments.map(
        d => d.device_relation,
      );
      const dashboardConfig: DashboardConfig | undefined =
        globalStore.clientAppSetting('config')?.value;
      const studyAppHandle = dashboardConfig?.studyClientAppHandle;
      if (!studyAppHandle) {
        throw new Error(
          '"studyClientAppHandle" is missing in "config" setting',
        );
      }
      this.studyAppHandle = studyAppHandle;
      this.loaded = true;
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
  }

  onStateUpdate(id: string, deviceBoxState: DeviceBoxState): void {
    this.deviceBoxStates.set(id, deviceBoxState);
    this.updateTileState();
  }

  updateTileState(): void {
    if (this.deviceBoxStates.size === 0) {
      this.tileState.color = 'is-warning';
      return;
    }
    let colorValue = TileColorMap['is-white'];
    // color of the tile is the 'highest' color of all device boxes contained in that tile
    this.deviceBoxStates.forEach(state => {
      if (TileColorMap[state.color] > colorValue) {
        colorValue = TileColorMap[state.color];
      }
    });
    this.tileState.color = getColorFromValue(colorValue);
  }
}

import { apiClientV2, Context } from '@/api/ApiClientV2';
import { DeviceAssignment } from '@/models/study/models';
import { globalStore } from '@/store/modules/global';
import { sortObjectsByArrayOfPropertyValues } from '@/util/sort';
import { DashboardConfig } from './interfaces';

export async function getDevicesAssignedToParticipant(
  participantId: string,
): Promise<DeviceAssignment[]> {
  const context: Context = {
    filter: {
      patient: participantId,
    },
    pagination: {
      page: 1,
      pageSize: 20,
    },
  };
  return apiClientV2.getListItems<DeviceAssignment>(DeviceAssignment, context);
}

export function filterAndSortDeviceAssignments(
  deviceAssignments: DeviceAssignment[],
): DeviceAssignment[] {
  const dashboardConfig: DashboardConfig =
    globalStore.clientAppSetting('config')?.value;

  const ignoreRoles = dashboardConfig?.ignoreRoles || [];
  const filteredDeviceAssignments = deviceAssignments.filter(d => {
    return !ignoreRoles.includes(d.role);
  });

  const roleOrder = dashboardConfig?.roleOrder;
  if (roleOrder !== undefined) {
    sortObjectsByArrayOfPropertyValues(
      filteredDeviceAssignments,
      'role',
      roleOrder,
    );
  }
  return filteredDeviceAssignments;
}



















import { Vue, Component, Prop } from 'vue-property-decorator';
import { BatteryStatus, Thresholds } from '../interfaces';
import MultilineTooltip from '@/components/common/MultilineTooltip.vue';
import { checkTimeThreshold } from '../helpers';
import { translate } from '@/lang/setup';

@Component({ components: { MultilineTooltip } })
export default class BatteryDisplay extends Vue {
  @Prop({ default: null }) batteryStatus!: BatteryStatus | null;
  @Prop({
    default: () => {
      return { lower: -10, upper: 600 };
    },
  })
  timeThreshold!: Thresholds;

  get color(): string {
    return checkTimeThreshold(this.batteryStatus?.time, this.timeThreshold)
      ? 'black'
      : 'gray';
  }

  get tooltip(): string {
    let tooltip = '';
    if (this.batteryStatus?.capacity !== undefined) {
      tooltip += `${translate('monitoring.battery.battery')}: ${
        this.batteryStatus.capacity
      } %\n`;
    }
    if (this.batteryStatus?.is_on_charger !== undefined) {
      tooltip += `${translate('monitoring.battery.onChargingStation')}: ${
        this.batteryStatus.is_on_charger
          ? translate('common.yes')
          : translate('common.no')
      }\n`;
    }
    if (this.batteryStatus?.is_charging !== undefined) {
      tooltip += `${translate('monitoring.battery.isCharging')}: ${
        this.batteryStatus.is_charging
          ? translate('common.yes')
          : translate('common.no')
      }\n`;
    }
    if (this.batteryStatus?.time !== undefined) {
      tooltip += `${translate('monitoring.tile.time')}: ${new Date(
        this.batteryStatus.time,
      ).toLocaleString()}`;
    }

    return tooltip;
  }

  get icon(): string {
    const prefix =
      this.batteryStatus?.is_on_charger || this.batteryStatus?.is_charging
        ? 'mdi-battery-charging'
        : 'mdi-battery';
    const capacity: number =
      Math.round((this.batteryStatus?.capacity ?? 0) / 10) * 10;
    let capacityStr: string;
    if (capacity === 0) {
      capacityStr = '-outline';
    } else if (capacity >= 100 || capacity < 0) {
      capacityStr = '';
    } else {
      capacityStr = '-' + capacity.toString();
    }
    return prefix + capacityStr;
  }
}





























































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { differenceInSeconds, formatDistanceStrict } from 'date-fns';
import { DeviceRelation } from '@/models/data/models';
import { Device } from '@/models/device/models';
import {
  TileConfig,
  DeviceBoxData,
  DeviceBoxState,
  TileParameter,
  ParameterState,
} from '@/apps/monitoring/interfaces';
import { Location } from 'vue-router';
import { monitoringRouteName } from '@/apps/monitoring/app';
import { getDateFnsLocale } from '@/lang/setup';
import { getParameters } from '@/apps/monitoring/helpers';
import MultilineTooltip from '@/components/common/MultilineTooltip.vue';
import BatteryData from './BatteryData.vue';
import DeviceHeader from './DeviceHeader.vue';

@Component({
  components: { MultilineTooltip, BatteryData, DeviceHeader },
})
export default class DeviceBox extends Vue {
  @Prop({ required: true }) deviceBoxData!: DeviceBoxData;
  @Prop({ required: true }) deviceRelation!: DeviceRelation;
  @Prop({ required: true }) device!: Device;
  @Prop({ required: true }) canEditDeviceSettings!: boolean;
  @Prop({ required: true }) tileConfig!: TileConfig;
  @Prop({ required: true }) deviceBoxState!: DeviceBoxState;
  @Prop({ required: true }) showAsBox!: boolean;
  @Prop({ default: false }) hasCustomModelConfig!: boolean;
  @Prop({ required: true }) tileEnabled!: boolean;
  @Prop({ default: true }) hasDetailView!: boolean;
  @Prop({ default: true }) hasBatteryInfo!: boolean;

  ParameterState = ParameterState;

  get timeAgo(): string {
    return this.transformTime(this.deviceBoxData?.time);
  }

  get tileParameters(): TileParameter[] {
    return getParameters(this.deviceBoxData, this.deviceBoxState);
  }

  /**
   * router location for detail view
   */
  get detailLocation(): Location {
    return {
      name: monitoringRouteName('dashboard-detail'),
      params: {
        id: this.deviceRelation.id,
      },
    };
  }

  get detailTooltipLabel(): string {
    return this.hasCustomModelConfig
      ? `${this.$tc('monitoring.tile.details')} (${this.$tc(
          'monitoring.tile.hasCustomThresholds',
        )})`
      : this.$tc('monitoring.tile.details');
  }

  /**
   * display time as distance, e.g. '5 min ago'
   */
  transformTime(time?: string): string {
    if (time === undefined) {
      return 'N/A';
    }
    const date = new Date(time);
    if (isNaN(date.valueOf())) {
      return 'N/A';
    }
    const diff = differenceInSeconds(date, new Date());
    const isCloseToNow = -15 < diff && diff < 15;
    if (isCloseToNow) {
      return this.$tc('common.now').toLowerCase();
    }
    return formatDistanceStrict(date, new Date(), {
      addSuffix: true,
      locale: getDateFnsLocale(),
      roundingMethod: 'round',
    });
  }

  /**
   * display time as locale string, e.g. '08/10/2020 16:17'
   */
  transformTimeTooltip(time?: string): string {
    if (time === undefined) {
      return 'N/A';
    }
    const date = new Date(time);
    if (isNaN(date.valueOf())) {
      return 'N/A';
    }
    return date.toLocaleString();
  }

  private formatLine(label: string, value?: string | number, unit?: string) {
    if (unit !== undefined) {
      return `${label}: ${value} ${unit}`;
    } else {
      return `${label}: ${value}`;
    }
  }

  private formatLineWithMap(
    label: string,
    value: string | number,
    unit?: string,
    map?: { [key: string]: string },
  ) {
    if (map?.[value] !== undefined) {
      return this.formatLine(label, map[value], unit);
    } else {
      return this.formatLine(label, value, unit);
    }
  }

  valueTooltip(param: TileParameter): string {
    const result = [];

    result.push(
      this.formatLine(
        this.$tc('monitoring.tile.value'),
        param.value,
        param.unit,
      ),
    );

    if (param.quality !== undefined) {
      result.push(
        this.formatLineWithMap(
          this.$tc('monitoring.tile.quality'),
          param.quality,
          param.qualityUnit,
          param.qualityMap,
        ),
      );
    }

    if (param.wearing !== undefined) {
      result.push(
        this.formatLineWithMap(
          this.$tc('monitoring.tile.wearing'),
          param.wearing,
          param.wearingUnit,
          param.wearingMap,
        ),
      );
    }

    result.push(
      this.formatLine(
        this.$tc('monitoring.tile.time'),
        this.transformTimeTooltip(param.time),
        '',
      ),
    );

    return result.join('\n');
  }

  enable(): void {
    this.tileConfig.enabled = true;
    this.$emit('writeConfig', this.tileConfig);
  }

  disable(): void {
    this.tileConfig.lastDisableEvent = new Date().toISOString();
    this.tileConfig.enabled = false;
    this.tileConfig.pid = '';
    this.$emit('writeConfig', this.tileConfig);
    // remove custom parameter data when disabling device
    this.$emit('deleteConfig');
  }
}

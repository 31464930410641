import { DeviceDataEvent } from '@/models/device/interfaces';
import {
  checkForNewData,
  checkValueWithinThreshold,
  getAge,
  getParamState,
} from './helpers';
import {
  StreamConfig,
  DeviceBoxData,
  EventData,
  ParameterData,
  DeviceBoxState,
  ParameterState,
  TileColor,
  ParameterThresholds,
  ParameterCompositeState,
} from './interfaces';

/**
 * Parse a device data event to get data for a monitoring dashboard tile
 * @param event
 * @param config
 */
export function parseData(
  event: DeviceDataEvent,
  config: StreamConfig,
): DeviceBoxData {
  if (event?.payload) {
    let eventData: EventData | undefined;
    if (config.usesDataJsonString && event.payload.data_json) {
      eventData = JSON.parse(event.payload.data_json);
    } else {
      eventData = event.payload.data;
    }
    if (eventData === undefined) {
      return { parameters: [], time: undefined };
    }

    const parameters: ParameterData[] = [];
    config.parametersConfig.forEach(paramConfig => {
      if (eventData === undefined) {
        return;
      }
      // find indices
      const valueIndex = eventData.columns.findIndex(
        e => e === paramConfig.valueColumn,
      );
      const qualityIndex = eventData.columns.findIndex(
        e => e === paramConfig.qualityColumn,
      );
      const wearingIndex = eventData.columns.findIndex(
        e => e === paramConfig.wearingColumn,
      );

      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        valueColumn,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        qualityColumn,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        wearingColumn,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        timeColumn,
        ...rest
      } = paramConfig;

      const dataPoints: ParameterData[] = eventData.data.map((d, i) => {
        return {
          ...rest,
          value: d[valueIndex],
          quality: d[qualityIndex],
          wearing: d[wearingIndex],
          time: getTime(eventData!, i, paramConfig.timeColumn),
        };
      });

      if (config.timeAscending) {
        dataPoints.reverse();
      }

      const filteredDataPoint = findDataPointWithinQualityAndWearingThreshold(
        dataPoints,
        config.checkLastSeconds,
      );

      parameters.push(filteredDataPoint);
    });

    return { parameters, time: parameters?.[0]?.time ?? event.time };
  } else {
    return { parameters: [], time: undefined };
  }
}

function getTime(
  eventData: EventData,
  index: number,
  timeColumn: string | number,
): string | undefined {
  if (timeColumn === 'useIndex') {
    return eventData.index[index] as string;
  } else {
    const timeIndex = eventData.columns.findIndex(e => e === timeColumn);
    return eventData.data[index][timeIndex] as string | undefined;
  }
}

function findDataPointWithinQualityAndWearingThreshold(
  dataPoints: ParameterData[],
  checkLastSeconds?: number,
): ParameterData {
  if (
    checkLastSeconds === undefined ||
    checkLastSeconds === 0 ||
    dataPoints[0].time === undefined
  ) {
    return dataPoints[0];
  } else {
    const timeThreshold =
      new Date(dataPoints[0].time).getTime() - checkLastSeconds * 1000;
    for (const dataPoint of dataPoints) {
      if (!dataPointIsRecentEnough(dataPoint, timeThreshold)) {
        break;
      }
      if (checkQualityAndWearingWithinThreshold(dataPoint)) {
        return dataPoint;
      }
    }
    // if no data point within quality threshold was found, return the most recent
    return dataPoints[0];
  }
}

function dataPointIsRecentEnough(
  dataPoint: ParameterData,
  timeThreshold: number,
): boolean {
  if (dataPoint.time === undefined) {
    return false;
  } else {
    return new Date(dataPoint.time).getTime() > timeThreshold;
  }
}

function checkQualityAndWearingWithinThreshold(
  dataPoint: ParameterData,
): boolean {
  return (
    getSubState('quality', dataPoint.quality, dataPoint.thresholds) ===
      ParameterState.OK &&
    getSubState('wearing', dataPoint.quality, dataPoint.thresholds) ===
      ParameterState.OK
  );
}

/**
 * Get the state of a monitoring dashboard tile from the tile data
 * @param data
 * @param enabled
 * @param lastDisableEvent
 */
export function getState(
  data: DeviceBoxData,
  enabled: boolean,
  lastDisableEvent: string | null,
  hideValue: boolean,
): DeviceBoxState {
  if (!enabled) {
    return {
      color: 'is-white',
      hasNewData: false,
      parameters: [],
      enabled: false,
    };
  }

  // check if event is older than lastDisableEvent
  if (!checkForNewData(data.time, lastDisableEvent)) {
    return {
      color: 'is-warning',
      hasNewData: false,
      parameters: [],
      enabled: true,
    };
  }

  // first step is to get state of each parameter individually
  const parameters: DeviceBoxState['parameters'] = [];
  const keys: ('value' | 'quality' | 'wearing' | 'time')[] = [
    'value',
    'quality',
    'wearing',
    'time',
  ];
  // for each parameter
  data.parameters.forEach(param => {
    const state: ParameterCompositeState['state'] = {
      value: ParameterState.OK,
      quality: ParameterState.OK,
      wearing: ParameterState.OK,
      time: ParameterState.OK,
    };
    for (const key of keys) {
      if (key === 'time') {
        if (param['time'] !== undefined) {
          state[key] = getSubState(
            key,
            getAge(param['time']),
            param?.thresholds,
          );
        }
      } else if (hideValue && key === 'value') {
        state[key] = ParameterState.HIDDEN;
      } else {
        state[key] = getSubState(key, param[key], param?.thresholds);
      }
    }
    parameters.push({
      id: param.id,
      state,
    });
  });

  // second step is to get state of whole tile
  let color: TileColor | undefined = undefined;
  // if any parameter is danger -> color is danger
  parameters.forEach(p => {
    if (getParamState(p) === ParameterState.DANGER) {
      color = 'is-danger';
      return;
    }
  });
  if (!color) {
    // if any parameter is warning -> color is warning
    parameters.forEach(p => {
      if (getParamState(p) === ParameterState.WARNING) {
        color = 'is-warning';
        return;
      }
    });
  }
  if (!color) {
    let allHidden = true;
    // if all parameters are hidden -> color is warning
    parameters.forEach(p => {
      if (getParamState(p) !== ParameterState.HIDDEN) {
        allHidden = false;
        return;
      }
    });
    if (allHidden) {
      color = 'is-warning';
    }
  }
  if (!color) {
    color = 'is-success';
  }

  return {
    color,
    hasNewData: true,
    parameters,
    enabled: true,
  };
}

function getSubState(
  key: 'value' | 'quality' | 'wearing' | 'time',
  valueToBeChecked?: string | number | null,
  thresholds?: ParameterThresholds,
): ParameterState {
  if (typeof valueToBeChecked === 'string') {
    return ParameterState.OK;
  }
  if (!checkValueWithinThreshold(valueToBeChecked, thresholds?.[key]?.hide)) {
    return ParameterState.HIDDEN;
  }
  if (!checkValueWithinThreshold(valueToBeChecked, thresholds?.[key]?.danger)) {
    return ParameterState.DANGER;
  }
  if (
    !checkValueWithinThreshold(valueToBeChecked, thresholds?.[key]?.warning)
  ) {
    return ParameterState.WARNING;
  }
  return ParameterState.OK;
}

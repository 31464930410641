import { apiClientV2 } from '@/api/ApiClientV2';
import { DeviceRelation } from '@/models/data/models';
import { Device } from '@/models/device/models';
import { globalStore } from '@/store/modules/global';
import { sortObjectsByArrayOfPropertyValues } from '@/util/sort';
import { DashboardConfig } from './interfaces';

export async function getDevicesAssignedToGateway(
  gateway: Device,
): Promise<DeviceRelation[]> {
  const deviceSettings = await Device.getSettings(apiClientV2, gateway.id);
  const dashboardConfig: DashboardConfig =
    globalStore.clientAppSetting('config')?.value;
  const deviceIds: string[] = [];
  const devicePlugins = dashboardConfig?.devicePlugins || [];
  devicePlugins.forEach(plugin => {
    const pluginDeviceSetting = deviceSettings.find(s => {
      return s.key === plugin;
    });
    if (pluginDeviceSetting) {
      for (const key of Object.keys(pluginDeviceSetting.value?.devices)) {
        if (pluginDeviceSetting.value?.devices?.[key]?.device) {
          deviceIds.push(pluginDeviceSetting.value.devices[key].device);
        }
      }
    }
  });
  const promises: Promise<DeviceRelation>[] = [];
  deviceIds.forEach(id => {
    promises.push(
      apiClientV2.find<DeviceRelation>(DeviceRelation, {
        device: id,
        application: globalStore.selection.application?.id,
      }),
    );
  });
  return Promise.all(promises);
}

export function filterAndSortDeviceRelations(
  deviceRelations: DeviceRelation[],
): DeviceRelation[] {
  const dashboardConfig: DashboardConfig =
    globalStore.clientAppSetting('config')?.value;

  const ignoreRoles = dashboardConfig?.ignoreRoles || [];
  const filteredDeviceRelations = deviceRelations.filter(d => {
    return !ignoreRoles.includes(d.role);
  });

  const roleOrder = dashboardConfig?.roleOrder;
  if (roleOrder !== undefined) {
    sortObjectsByArrayOfPropertyValues(
      filteredDeviceRelations,
      'role',
      roleOrder,
    );
  }

  return filteredDeviceRelations;
}

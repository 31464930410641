






import { Vue, Component, Prop } from 'vue-property-decorator';
import { getRandomTimeoutMsec } from '../helpers';
import { BatteryStatus } from '../interfaces';
import { DeviceState } from '@/models/device/models';
import BatteryDisplay from './BatteryDisplay.vue';
import { globalStore } from '@/store/modules/global';

@Component({ components: { BatteryDisplay } })
export default class BatteryData extends Vue {
  @Prop({ required: true }) device!: string;

  batteryStatus: BatteryStatus | null = null;
  interval = 0;

  async mounted(): Promise<void> {
    this.batteryStatus = await this.getBatteryStatus();
    this.interval = setInterval(async () => {
      this.batteryStatus = await this.getBatteryStatus();
    }, getRandomTimeoutMsec(180, 5));
  }

  destroyed(): void {
    clearInterval(this.interval);
  }

  async getBatteryStatus(): Promise<BatteryStatus | null> {
    try {
      const deviceState = await this.$apiv2.find<DeviceState>(DeviceState, {
        device: this.device,
        application: globalStore.selection.application?.id,
      });
      if (deviceState?.data?.['device_last_battery_info']) {
        this.$emit(
          'batteryStatusChanged',
          deviceState.data['device_last_battery_info'],
        );
        return deviceState.data['device_last_battery_info'];
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // device state does not exist -> do nothing
      } else {
        this.$errorHandler.handleError(error);
      }
    }
    return null;
  }
}
